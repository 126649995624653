import mySortBar from "./components/sortBar";
import Util from "@/utils/utils";
import {getPage,updateAijiaOldMachineOrderStatus,getExport} from './api'
export default {
  components: {
    mySortBar
  },
  data() {
    return {
      sortList: [
        //0默认 1降 2升序
        { name: "录入时间排序", sortStatus: 1, sortName: "createdDate" },
        { name: "预计上门时间", sortStatus: 0, sortName: "expectedDate" },
      ],
      nolistImg: require("@/assets/IntentionList/none.png"),
      pageLoadFlag: false,
      isFixed: false,

      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/tradeInoldForNew",
          name: "",
          title: "旧机订单列表"
        }
      ],
      TVIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/tv.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797185&Signature=gmnN9cJCWEJqHgoSsysnVf5WwCg%3D',
      BXIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/bx.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797122&Signature=iggDn2fhqfqW1aeaxbMIli9E7jE%3D',
      KTIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/kt.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797163&Signature=ur2HLjmIfnNwD%2FnqD%2FPENZWvEso%3D',
      XYJIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/xyj.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2341797202&Signature=xKtbvv1WIPgFj3z8DIBepnm%2BuD8%3D',
      CWIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/cw.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2036632507&Signature=MIXXULYnj%2BujgpTdwhp3X2Z4l3M%3D',
      SJIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/sjbf.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2036632411&Signature=oIGDhtcrZ8b%2Bb4lvoWf3mCQ6r5g%3D',
      LGIMG: 'https://hisense-xtw.oss-cn-qingdao.aliyuncs.com/pc/img/lgbf.png?OSSAccessKeyId=LTAI5tDESpESdwLKVUntL1ns&Expires=2036632490&Signature=3cnthLClyB%2B5YGzMW6%2BxZEuSZpw%3D',
      
      //列表数据
      listData: [],
      allCherkFlag: false,// 全选按钮标志
      batchCherkFlag: false,// 批量勾选标识
     
      // 分页条件
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 非配置筛选条件
      filterData: {
        orderBy: "t.createdDate desc",// 排序选中的数据id
        // sortOrder:''
      },
      arg: {},
      headInfo: {},
      isLoading:false,
      cherkExoprtId:[],
      type:'', // CANSEL-取消收旧, RECYCLED-上门回收,DONE-自行处理,NOTICED-通知收旧商
      totalPages:'',// 全部页面
      totalRows:'0',
      currentPage:'',// 当前页
      message:'',// 提示信息
      visibleInfo:false,// 弹窗的显隐
      itemId:'',// 取消收旧 上门回收id
      plDisabled:true,
    };
  },
  mounted() {
    // window.addEventListener(
    //   "scroll",
    //   this.throttle(this.handleScroll, 10),
    //   true
    // );
  },
  methods: { 
    exportClick() {
      let data = {
        pageNo: this.page.pageNum,
        pageSize:this.page.pageSize,
        ...this.filterData,
        ...this.arg
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "旧机订单列表.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    // 加载更多
    loadMore() {
       this.page.pageNum =  this.page.pageNum + 1,
      this.getList()
    },
    showBottom(type) {
      this.type = type
      this.isFixed = true
      this.$forceUpdate()
    },
    // 获取列表数据
    getList() {
      let data = {
        pageNo: this.page.pageNum,
        pageSize:this.page.pageSize,
        ...this.filterData,
        ...this.arg
      } 
      getPage(data).then(res=>{
        if(res.data.code == 0) {
          this.totalRows = res.data.totalRows
          this.totalPages = res.data.totalPages
          this.currentPage =  res.data.currentPage
          if( this.page.pageNum > 1 && res.data.list.length>0) {
            this.listData = this.listData.concat(res.data.list);
          } else {
            this.listData = res.data.list
          }
          if(res.data.totalPages == 0) {
            this.listData = []
          }
          if(this.listData.length>0) {
            this.listData.forEach(item => {
              item.ischerk = false
            })
          }
     
          if (this.allCherkFlag ) {
            this.listData.forEach(item => {
              if( item.status =='RECYCLED') {
                item.ischerk = true;
                this.cherkExoprtId.push(item.id)
              }
            });
          }
             //  如果选择的id>=5个才能进行操作 否则不允许操作
            if(this.cherkExoprtId.length >= 5){
              this.plDisabled = false
            }else{
              this.plDisabled = true
            }
            
          
        } else {
          this.listData = []
          this.totalPages = 0
          this.$message.warning('查询失败'+res.data.msg)
        }
        this.pageLoadFlag=false
      }).catch(error=>{
        this.pageLoadFlag=false
      })
    },
     //表单筛选
     searchFun(arg) {
      this.arg = arg[0];
      this.page.pageNum = 1;
      this.page.pageSize = 10
      this.getList()
      // this.pageLoadFlag = true;

  },
    //  查看详情
    gotoDeatil(id) {
        // this.$router.push({
        //     path:'/tradeInoldForNewDetail',
        //     query:{
        //         id:id
        //     }
        // })
        let routeUrl = this.$router.resolve({
          path: "/tradeInoldForNewDetail",
          query: { id: id }
        });
        window.open(routeUrl.href, "_blank");
    },
   //筛选排序
   sortClick(data) {
    const { sortName, sortStatus } = data;
    this.page.pageNum = 1
    this.filterData = {
      ...this.filterData,
      // sortName: sortName,
      orderBy: sortStatus == 0 ? "" : sortStatus == 2 ? `t.${sortName} asc` : `t.${sortName} desc`,
      ...this.page
    };
    this.getList()
  
  
  },
    
    // 底部固定部分代码
    //throttle：
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    // handleScroll() {
    //   if (this.$refs.footerRef) {
    //     this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
    //   }
    // },
    
   
  
    // 多选框勾选
    changeItemCherk(e, item) {
      item.ischerk = e.target.checked;
      if(item.ischerk){
        this.cherkExoprtId.push(item.id)
      } else {
        for (let i = 0; i < this.cherkExoprtId.length; i++) {
          if (this.cherkExoprtId[i] == item.id) {
            this.cherkExoprtId.splice(i, 1);
          }
        }
      }
      this.$forceUpdate();
      let num = 0;
      this.listData.forEach(item => {
        if (item.ischerk == false) {
          num += 1;
        }
      });

      if (this.cherkExoprtId.length <= 0) {
        this.allCherkFlag = false;
    
        this.$forceUpdate();
      } else if (this.cherkExoprtId.length == this.listData.length) {
        this.allCherkFlag = true;
      
        this.$forceUpdate();

      } else if (this.cherkExoprtId.length < this.listData.length) {
        this.batchCherkFlag = true;
    
        this.$forceUpdate();
      }
      //  如果选择的id>=5个才能进行操作 否则不允许操作
      if(this.cherkExoprtId.length >= 5){
        this.plDisabled = false
      }else{
        this.plDisabled = true
      }
      
    },
   
    //  全选
    allCherk(e) {
      // 全选按钮
      this.allCherkFlag = e.target.check;
      if (e.target.checked) {
        this.cherkExoprtId = []
        this.batchCherkFlag = false
        this.listData.forEach(item => {
          if( item.status =='RECYCLED') {
            item.ischerk = true;
            this.cherkExoprtId.push(item.id)
          }
        
        });
      } else {
        this.listData.forEach(item => {
          item.ischerk = false;
          this.cherkExoprtId = []
        });
        this.plDisabled = true
      }
      if(this.cherkExoprtId.length >= 5){
        this.plDisabled = false
      }
      this.$forceUpdate();
    },
    // 批量
    batchCherk(e) {
      if (!e.target.checked) {
        this.cherkExoprtId = []
        this.listData.forEach(item => {
          item.ischerk = false
        })
      } else {
        this.allCherkFlag = false
      }
    },
    //  取消全选
    cancelChoose(){
      this.cherkExoprtId = []
      this.allCherkFlag = false
      this.isFixed = false
      this.plDisabled = true
      this.listData.forEach(item => {
        item.ischerk = false;
      });
    },
    // 批量自行处理
    handle(e,item,row) {
      if(!row&&(e == 'NOTICED'||e=='DONE') && this.cherkExoprtId.length<=0) {
        this.$message.warning('请选择数据')
        return
      }
      if(e == 'NOTICED' && this.cherkExoprtId.length<5) {
        this.$message.warning('通知收旧商需要选择5条及以上才可操作')
        return
      }
      // 'TODO'-待处理，TO_RECYCLED-待上门回收, RECYCLED-已回收到店,NOTICED-已通知收旧商,CANCEL-已取消,DONE-已完成
      this.type = e
    
      switch (e) {  
        case 'CANCEL':
          this.cancelChoose()
          this.itemId = item.id
          this.message='请确认是否取消该收旧信息！'
          break;
        case 'TO_RECYCLED':
          this.cancelChoose()
          this.itemId = item.id
          this.message='请确认是否已上门拉回旧机！'
            break;
        case 'DONE':
          if(row){
            this.cancelChoose()
            this.itemId = item.id
          }else {
            this.itemId = ''
          }
          this.message='请确认该旧机是否自行处理！'
          break;
        case 'NOTICED':
          this.itemId = ''
          this.message='请确认是否通知收旧商回收旧机！'
          break;
          case 'RECYCLED':
            this.cancelChoose()
            this.itemId = item.id
            this.message='请确认是否拉回旧机！'
            break;
        }
        this.visibleInfo = true
    },
    //  更新状态
    delect() {
      this.pageLoadFlag = true
      let data = {
        dealMethod:(this.type=='NOTICED' || this.type=='DONE')?this.type=='NOTICED'?2:1:'',
        idList:this.itemId?[this.itemId]: this.cherkExoprtId,
        status:this.type
      }
      updateAijiaOldMachineOrderStatus(data).then(res=>{
        if(res.data.code == 0){
          this.$message.success('操作成功')
          this.plDisabled = true
          this.getList()
          this.pageLoadFlag = false
        }else {
          this.$message.warning(res.data.msg)
           this.pageLoadFlag = false
        }
      }).catch(err=>{
        this.pageLoadFlag = false
      })
    }
    
  }

};
