/*
 * @Author: mayu.ex 15163353598@163.com
 * @Date: 2024-03-20 14:13:34
 * @LastEditors: mayu.ex 15163353598@163.com
 * @LastEditTime: 2024-03-25 14:58:05
 * @FilePath: \B2B\src\views\tradeInoldForNew\api.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import http from "@/utils/request";
// 列表查询
export function getPage(data) {
  return http({
    method: "post",
    url: "/fast/oldMachineOrder/oldMachineOrder/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}
// 更新状态
export function updateAijiaOldMachineOrderStatus(data) {
  return http({
    method: "post",
    url: "/aijiaCoupon/updateAijiaOldMachineOrderStatus.nd",
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    data
  });
}
// 查看详情
export function queryDetail(data) {
  return http({
    method: "get",
    url: "/aijiaCoupon/queryDetail.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    params:data
  });
}
// 导出
export function getExport(data) {
  return http({
    url: '/fast/oldMachineOrder/oldMachineOrder/export.nd',
    method: 'get',
    params: data,
    contentType: "application/json;charset=UTF-8",
    type: 'stringfy',
    responseType: 'blob'
  })
}


